// import MaterialReactTable from 'material-react-table'
import React, { useContext, useState, useEffect } from "react";
import { columns } from "./columns";
import {
  Box,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  Skeleton,
  ToggleButton,
  ToggleButtonGroup,
  MenuItem,
  Select,
  FormControl,
  InputLabel,
} from "@mui/material";

import {
  minimalMuiTableProps,
  styledTableProps,
} from "../../../../styles/muiProps";

import {
  MRT_EditActionButtons,
  MaterialReactTable,
  useMaterialReactTable,
} from "material-react-table";
import { AuthContext } from "../../../../context/authContext";
import ActionBar from "../../../../components/ActionBar";
import { archiveProject, createProject, updateProject } from "./api";
import { categories, validateProject } from "../utils";
import {
  useCreateProject,
  useDeleteProject,
  useGetProjects,
  useUpdateProject,
} from "./projectHooks";
import { EditableTable } from "../components/ProjectEditabletable";
import ProjectMembersModal from "../components/ProjectMembersModal";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { getAllUsernames, getAllUsers } from "../../../../api/usersApi";
import axios from "../../../../api/axios";

const initialData = {
  name: "",
  projectLeads: [],
  members: [],
  tasks: {},
};

const ProjectList = () => {
  const [validationErrors, setValidationErrors] = useState({});
  const { accessToken, auth } = useContext(AuthContext);

  const currentUser = auth?.role;

  const [filterType, setFilterType] = useState("");
  const [filteredProjects, setFilteredProjects] = useState();
  const [showArchivedProjects, setShowArchivedProjects] = useState(false);
  const [clients, setClients] = useState(["chinookdt", "CWT", "CBL"]);
  const [selectedClient, setSelectedClient] = useState("");

  const [selectedProject, setSelectedProject] = useState(null);
  const [isModalOpen, setModalOpen] = useState(false);

  const {
    isLoading,
    isError,
    error,
    isSuccess,
    data: user_data,
  } = useQuery({
    queryKey: ["users"],
    queryFn: () => getAllUsernames(accessToken),
    enabled: currentUser === "admin" || currentUser === "owner",
  });

  console.log({ currentUser });

  const { data: projectsData, isLoading: projectLoading } = useGetProjects({
    accessToken,
  });
  useEffect(() => {
    const getAllClients = async () => {
      const clientsData = await axios.get("/clients/all", {
        headers: {
          Authorization: `Bearer ${accessToken}`,
        },
      });
      setClients(clientsData.data.map((c) => c.name));
      // console.log("ccccccccc", clientsData.data);
    };
    getAllClients();
  }, []);

  useEffect(() => {
    if (!projectsData) return;

    // console.log(projectsData);
    let projects = projectsData?.filter((project) => !project.isArchived) || [];
    // console.log("filerter?", projects);
    if (filterType)
      projects = projects.filter((project) => project.type === filterType);

    setFilteredProjects(projects);
  }, [filterType, projectsData]);

  const hooks = {
    useCreateItem: useCreateProject,
    useGetItems: useGetProjects,
    useUpdateItem: useUpdateProject,
    useDeleteItem: useDeleteProject,
  };

  const toggleArchiveButton = () => {
    setShowArchivedProjects((prev) => !prev);
  };

  const actions = {
    validateItem: validateProject,
    archiveItem: archiveProject,
  };

  const additionalProps = {
    clients: clients,
    categories: categories,
  };
  const handleClientChange = (event) => {
    setSelectedClient(event.target.value);
    if (!event.target.value) {
      //   console.log("cccc", event.target.value);
      setFilteredProjects(projectsData);
    } else {
      setFilteredProjects(
        projectsData.filter((p) => p.clientName === event.target.value)
      );
    }
  };

  // Add this function to handle opening the modal with the selected project
  const openMembersModal = (project) => {
    setSelectedProject(project); // Set the selected project
    setModalOpen(true); // Open the modal
  };

  // Add this function to handle closing the modal
  const closeMembersModal = () => {
    setSelectedProject(null); // Clear the selected project
    setModalOpen(false); // Close the modal
  };

  return (
    <Box>
      <ToggleButtonGroup
        value={filterType}
        exclusive
        onChange={(event, filterType) => {
          setFilterType(filterType);
        }}
        aria-label="task filter"
        sx={{ marginBottom: 2 }}
      >
        {categories.map((category) => (
          <ToggleButton value={category} aria-label={category}>
            {category}
          </ToggleButton>
        ))}
      </ToggleButtonGroup>
      <FormControl sx={{ minWidth: 200, marginBottom: 2, marginLeft: 2 }}>
        <InputLabel id="client-filter-label">Filter by Client</InputLabel>
        <Select
          labelId="client-filter-label"
          value={selectedClient}
          onChange={handleClientChange}
          label="Filter by Client"
        >
          <MenuItem value="">
            <em>All Clients</em>
          </MenuItem>
          {clients.map((client) => (
            <MenuItem key={client} value={client}>
              {client}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      {currentUser ? (
        <EditableTable
          columns={columns(user_data || [], clients, openMembersModal)}
          name="Project"
          actions={actions}
          hooks={hooks}
          editMode="modal"
          enableEditing={
            currentUser === "admin" || currentUser === "owner" ? true : false
          }
          enableCreate={
            currentUser === "admin" || currentUser === "owner" ? true : false
          }
          enableArchive={true}
          additionalProps={additionalProps}
          initialData={initialData}
          data={filteredProjects}
        />
      ) : (
        <>
          <Skeleton
            animation="wave"
            variant="text"
            sx={{ fontSize: "1rem" }}
            height={60}
          />

          {/* For other variants, adjust the size with `width` and `height` */}
          <Skeleton animation="wave" variant="rectangular" height={"50vh"} />
        </>
      )}
      <br />
      <Button variant="contained" onClick={toggleArchiveButton}>
        {showArchivedProjects
          ? "Hide Archived Projects"
          : "Show Archived Projects"}
      </Button>
      {showArchivedProjects && currentUser ? (
        <EditableTable
          columns={columns(user_data || [], clients, openMembersModal)}
          name="Project"
          actions={actions}
          hooks={hooks}
          editMode="modal"
          enableEditing={false}
          enableCreate={false}
          enableArchive={false}
          additionalProps={additionalProps}
          initialData={initialData}
          data={projectsData?.filter((project) => project.isArchived) || []}
        />
      ) : (
        <></>
      )}

      {selectedProject && (
        <ProjectMembersModal
          open={isModalOpen}
          onClose={closeMembersModal}
          projectId={selectedProject}
          accessToken={accessToken}
        />
      )}
    </Box>
  );
};

export default ProjectList;
